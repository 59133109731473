import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      The future home of Matt Lavin's website
      <br />
      One time I presented at Triangle ReactJS
    </div>
  );
}

export default App;
